import React, { Component } from 'react';
import Image from "../image"

class HedgeMaintenanceEntry extends Component {
  render() {
    return <div className="contentful-entry">
      <h3 className="title is-size-6 is-size-7-mobile">{this.props.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
      {this.props.image && (
        <div className="entry-image has-text-centered">
          <Image url={this.props.image.image.file.url} alt={this.props.image.altText} />
        </div>
      )}
      <hr />
    </div>
  }
}

export default HedgeMaintenanceEntry;